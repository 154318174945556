// Import statements remain unchanged
import { isString } from 'lodash';
import { Icon } from '@iconify/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, Theme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Paper,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { SxProps } from '@mui/system';
// utils
import { fData } from '../../utils/formatNumber';
// Components
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadFile } from '@mui/icons-material';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

// Style for the drop zone
const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark' ? '#333D48' : theme.palette.background.neutral,
  border: `1px solid ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

// Define CustomFile interface
interface CustomFile extends File {
  path?: string;
  preview?: string;
}

// Update UploadMultiFileProps to include onChange
interface UploadMultiFileProps extends DropzoneOptions {
  error?: boolean;
  files: (File | string)[];
  showPreview: boolean;
  onChange: (uploadedFiles: (File | string)[]) => Promise<void>; // Add this line
  onRemove: (file: File | string) => void;
  onRemoveAll: VoidFunction;
  sx?: SxProps<Theme>;
  dropBoxStyles?: SxProps<Theme>;
  imgStyles?: SxProps<Theme>;
  multiple?: boolean;
  hideFilePreview?: boolean;
}

// Function to get file data
const getFileData = (file: CustomFile | string) => {
  if (typeof file === 'string') {
    return {
      key: file
    };
  }

  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview
  };
};

// Main UploadMultiFile component
export default function UploadMultiFile({
  error,
  showPreview = false,
  hideFilePreview = false,
  files,
  onChange, // Add onChange here
  onRemove,
  onRemoveAll,
  dropBoxStyles,
  imgStyles,
  sx,
  multiple = true,
  ...other
}: UploadMultiFileProps) {
  const hasFile = files?.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    onDrop: (acceptedFiles) => {
      // Handle file change here
      onChange([...files, ...acceptedFiles]); // Call onChange with new files
    },
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  const { translate } = useLocales();

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...dropBoxStyles
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            p: 3,
            ml: { md: 2 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <UploadFile />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {translate('upload your files here')}
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
      {files && (
        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {files?.[0] !== undefined &&
              files?.map((file) => {
                if (file) {
                  const { key, name, size, preview } = getFileData(file as CustomFile);

                  if (showPreview) {
                    return (
                      <ListItem
                        key={key}
                        component={motion.div}
                        {...varFadeInRight}
                        sx={{
                          p: 0,
                          m: 0.5,
                          width: 80,
                          height: 80,
                          borderRadius: 1.5,
                          overflow: 'hidden',
                          position: 'relative',
                          display: 'inline-flex'
                        }}
                      >
                        <Paper
                          variant="outlined"
                          component="img"
                          src={isString(file) ? file : preview}
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute'
                          }}
                        />
                        <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                          <MIconButton
                            size="small"
                            onClick={() => onRemove(file)}
                            sx={{
                              p: '2px',
                              color: 'common.white',
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                              '&:hover': {
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                              }
                            }}
                          >
                            <Icon icon={closeFill} />
                          </MIconButton>
                        </Box>
                      </ListItem>
                    );
                  }
                  return null; // Ensure a return statement
                }
                return null; // Ensure a return statement
              })}
          </AnimatePresence>
        </List>
      )}
    </Box>
  );
}

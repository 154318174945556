import { useEffect } from 'react';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import Page from '../../../components/Page';
import B2bCompanyForm from '../../../components/_dashboard/b2b/B2bCompanyForm';
import { getB2bCompany } from 'redux/slices/b2b';
import { B2bCompanies } from '../../../@types/b2b';

type LawyerFormProps = {
  name: string;
  setDisplay: Function;
  AvailabilityHandler: Function;
  b2b: B2bCompanies;
};
export default function B2bCompanyCreate({
  name,
  setDisplay,
  AvailabilityHandler,
  b2b
}: LawyerFormProps) {
  const dispatch = useDispatch();
  const isEdit = name === '' ? false : true;

  const { b2bCompany, isLoading } = useSelector((state: RootState) => state.b2b);


  useEffect(() => {
    if (isEdit) {
      dispatch(getB2bCompany(name));
    }
  }, [dispatch, name, isEdit]);

  return (
    <Page>
      <B2bCompanyForm isEdit={isEdit} b2bCompany={b2b} setDisplay={setDisplay} />
    </Page>
  );
}

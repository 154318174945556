import axios from 'axios';
import { BLOGS_URL } from './axios';

let token: string | null = null;
let tokenExpirationTime: number | null = null;

export async function getToken(): Promise<string | null> {
  if (!token || isTokenExpired()) {
    token = await fetchToken();
  }
  return token;
}

function isTokenExpired(): boolean {
  if (!tokenExpirationTime) return true;
  return Date.now() >= tokenExpirationTime;
}


async function fetchToken(): Promise<string | null> {
  try {
    const response = await axios.post(`${BLOGS_URL}/Account/SignIn`, {
      userName: 'ShwraAdmin',
      password: 'Admin!2345'
    });

    token = response.data?.accessToken;
    const expiresIn = 3600;
    tokenExpirationTime = Date.now() + expiresIn * 1000;

    return token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
}

export async function refreshToken(): Promise<string | null> {
  token = await fetchToken();
  return token;
}

export function setupTokenRefresh(): void {
  const refreshInterval = 3600 * 1000 - 60 * 1000;
  setInterval(async () => {
    if (isTokenExpired()) {
      try {
        await refreshToken();
      } catch (error) {
        console.error('Error refreshing token silently:', error);
      }
    }
  }, refreshInterval);
}
setupTokenRefresh();

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  forgetPassword: path(ROOTS_AUTH, '/forget-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  consultant: path(ROOTS_AUTH, '/consultation-form'),
  completeProfile: path(ROOTS_AUTH, '/profile/complete'),

  accountPending: path(ROOTS_AUTH, '/account/pending'),
  accountBlocked: path(ROOTS_AUTH, '/account/blocked')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: path(ROOTS_DASHBOARD, '/general-dashboard'),
  News_managment: {
    details: path(ROOTS_DASHBOARD, '/news/details'),
    list: path(ROOTS_DASHBOARD, '/news/list'),
    edit: path(ROOTS_DASHBOARD, "/news/edit"),
    add: path(ROOTS_DASHBOARD, "/news/add")
  },
  Customer_b2b: {
    details: path(ROOTS_DASHBOARD, '/Customerb2b/details'),
    list: path(ROOTS_DASHBOARD, '/Customerb2b/list')
  },
  Master_Category: {
    details: path(ROOTS_DASHBOARD, '/MasterCategory/details'),
    list: path(ROOTS_DASHBOARD, '/MasterCategory/list')
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  packages: {
    root: path(ROOTS_DASHBOARD, '/packages'),
    list: path(ROOTS_DASHBOARD, '/packages/list'),
    add: path(ROOTS_DASHBOARD, '/packages/new')
  },
  features: {
    root: path(ROOTS_DASHBOARD, '/features'),
    list: path(ROOTS_DASHBOARD, '/features/list'),
    add: path(ROOTS_DASHBOARD, '/features/new')
  },
  promotions: {
    root: path(ROOTS_DASHBOARD, '/promotions'),
    list: path(ROOTS_DASHBOARD, '/promotions/list'),
    add: path(ROOTS_DASHBOARD, '/promotions/new')
  },
  request: {
    root: path(ROOTS_DASHBOARD, '/request'),
    adminRoot: path(ROOTS_DASHBOARD, '/request/list/admin'),
    clientRoot: path(ROOTS_DASHBOARD, '/request/list/client'),
    lawyerRoot: path(ROOTS_DASHBOARD, '/request/list/lawyer'),
    newRequest: path(ROOTS_DASHBOARD, '/request/new')
  },

  lawyer: {
    root: path(ROOTS_DASHBOARD, '/lawyer'),
    profile: path(ROOTS_DASHBOARD, '/lawyer/profile'),
    list: path(ROOTS_DASHBOARD, '/lawyer/list'),
    newLawyer: path(ROOTS_DASHBOARD, '/lawyer/new'),
    editById: path(ROOTS_DASHBOARD, `/lawyer/reece-chung/edit`)
  },

  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    profile: path(ROOTS_DASHBOARD, '/client/profile'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    newClient: path(ROOTS_DASHBOARD, '/client/new'),
    editById: path(ROOTS_DASHBOARD, `/client/reece-chung/edit`)
  },

  calendar: {
    root: path(ROOTS_DASHBOARD, '/calendar')
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    configurations: path(ROOTS_DASHBOARD, '/user/configurations')
  },

  availability: {
    root: path(ROOTS_DASHBOARD, '/availability/schedules')
  },

  appointments: {
    root: path(ROOTS_DASHBOARD, '/appointments'),
    admin: path(ROOTS_DASHBOARD, '/admin-appointments')
  },
  categories: {
    admin: path(ROOTS_DASHBOARD, '/categories'),
    createNew: path(ROOTS_DASHBOARD, '/categories/new')
  },
  complaints: {
    root: path(ROOTS_DASHBOARD, '/complaints'),
    list: path(ROOTS_DASHBOARD, '/complaints/list')
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    list: path(ROOTS_DASHBOARD, '/report/list')
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    list: path(ROOTS_DASHBOARD, '/employee/list')
  },
  musher: {
    root: path(ROOTS_DASHBOARD, '/musher'),
    profile: path(ROOTS_DASHBOARD, '/musher/profile'),
    list: path(ROOTS_DASHBOARD, '/musher/list')
  },
  b2b: {
    root: path(ROOTS_DASHBOARD, '/b2b'),
    profile: path(ROOTS_DASHBOARD, '/b2b/profile'),
    list: path(ROOTS_DASHBOARD, '/b2b/list'),
    customerLists: path(ROOTS_DASHBOARD, '/b2b/customer-list'), // New route added here
    root2: path(ROOTS_DASHBOARD, '/b2b')
  },
  ramdan: {
    root: path(ROOTS_DASHBOARD, '/ramdan'),
    admin: path(ROOTS_DASHBOARD, '/admin-ramdan')
  },
  questions: {
    root: path(ROOTS_DASHBOARD, '/questions'),
    list: path(ROOTS_DASHBOARD, '/questions/list')
  },
  permission: {
    root: path(ROOTS_DASHBOARD, '/permission'),
    list: path(ROOTS_DASHBOARD, '/permission/list')
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    list: path(ROOTS_DASHBOARD, '/blogs/list')
  },
  scheduleDemo: {
    root: path(ROOTS_DASHBOARD, '/scheduleDemo'),
    list: path(ROOTS_DASHBOARD, '/scheduleDemo/list'),
    details: path(ROOTS_DASHBOARD, '/scheduleDemo/details')
  }
};

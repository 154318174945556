import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from '../utils/axios';
import { INotification } from '../@types/notifications';
import { AxiosError } from 'axios';

export const notificationsKey = 'notifications';

export function useNotificationsQuery(page = 1, size = 12): UseQueryResult<{ data: Array<INotification>, totalCount: number, unReadCount?: number }> {
  return useQuery({
    queryKey: [notificationsKey, page],
    queryFn: ({ signal }) =>
      axios.get(`/account/notifications?page=${page}&size=${size}`, { signal })
        .then((res) => res?.data?.result),
    keepPreviousData: true,
  });
}


export function useNotificationReadMutation(): UseMutationResult<AxiosError, Array<INotification>> {
  return useMutation((notificationId: any) =>
    axios.patch('/account/NotificationsRead', { notificationId }).then((res) => res?.data?.result?.data)
  );
}

import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { CategoriesState } from '../../@types/categories';
import { createFileUrl } from 'components/file/FilePreview';

// ----------------------------------------------------------------------


const initialState: CategoriesState = {
  isLoading: false,
  error: false,
  categoriesList: [],
  // @ts-ignore
  categoryDetails: {
    mobileRequestCategoryId: 0,
    name: '',
    arabicName: '',
    description: '',
    arabicDescription: '',
    icon: '',
    isActive: false,
    sortOrder: 0,
  },
  addCategoryStatus: {},
  editCategoryStatus: {},
  deleteCategoryStatus: {},
  categoriesListLangWise: [],
  masterCategoriesListLangWise: [],
  IsCategoryCreated: null,
  addCategoryShowStatus: []
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ADMIN LIST
    getCategoriesListSuccess(state, action) {
      state.isLoading = false;
      state.categoriesList = action.payload;
    },
    // GET ADMIN LIST
    getCategoriesListLangWiseSuccess(state, action) {
      state.isLoading = false;
      state.categoriesListLangWise = action.payload;
    },
    // GET ADMIN LIST
    getMasterCategorieListLangWiseSuccess(state, action) {
      state.isLoading = false;
      state.masterCategoriesListLangWise = action.payload;
    },
    // GET APPOINTMENT STATUS
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.categoryDetails = action.payload;
    },
    addCategoryStatus(state, action) {
      state.isLoading = false;
      state.addCategoryStatus = action.payload;
    },
    editCategoryStatus(state, action) {
      state.isLoading = false;
      state.editCategoryStatus = action.payload;
    },
    deleteCategoryStatus(state, action) {
      state.isLoading = false;
      state.deleteCategoryStatus = action.payload;
    },
    setIsCategoryCreated(state, action) {
      state.IsCategoryCreated = action.payload;
    },
    setaddCategoryShowStatus(state, action) {
      state.addCategoryShowStatus = action.payload;
    }
  }
});

export default slice.reducer;

//-------------------------------------------------------------------------------------

export function getCategoriesList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/MasterCategory');
      dispatch(slice.actions.getCategoriesListSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesListLangWise() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/MobileRequestCategories/MobileRequestCategoryLanguageWise'
      );
      dispatch(slice.actions.getCategoriesListLangWiseSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMasterCategorieListLangWise() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/MasterCategory/MasterCategoryLanguageWise', {
        params: { IsCustomerV2: true }
      });
      dispatch(slice.actions.getMasterCategorieListLangWiseSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoryDetails(categoryId: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/MasterCategory/${categoryId}`);
      const icon: any = await createFileUrl({
        fileName: response?.data?.result?.icon,
        type: 'image'
      });

      if (icon) {
        icon.onload = () => {
          try {
            const image = icon.result;
            dispatch(slice.actions.getCategorySuccess({ ...response?.data?.result, icon: image }));
          } catch (err) {
            dispatch(slice.actions.getCategorySuccess({ ...response?.data?.result, icon: '' }));
          }
        };
      } else {
        dispatch(slice.actions.getCategorySuccess({ ...response?.data?.result, icon: '' }));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCategoryDetails(data: any) {
  return dispatch(slice.actions.getCategorySuccess(data));
}

export function addCategory(data: FormData,callBack: Function) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/MasterCategory`, data);

      if (response?.data?.status === false) {
        // Handle failure case
        const ErrorMessage = response?.data?.error[0];
        dispatch(slice.actions.setIsCategoryCreated(false));
        dispatch(slice.actions.setaddCategoryShowStatus(ErrorMessage));
        callBack(response);
      } else {
        const message = response?.data?.result[0];
        dispatch(getCategoriesList());
        dispatch(slice.actions.setIsCategoryCreated(true));
        dispatch(slice.actions.setaddCategoryShowStatus(message));
        callBack(response);
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.setIsCategoryCreated(false));
      dispatch(slice.actions.setaddCategoryShowStatus('An error occurred. Please try again.'));
    }
  };
}

export function setAddCategory(data: any) {
  return dispatch(slice.actions.addCategoryStatus(data));
}

export function editcategory(data: FormData, callBack: Function) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/MasterCategory`, data);

      if (response?.data?.status) {
        const message = response?.data?.result[0];
        dispatch(getCategoriesList());
        dispatch(slice.actions.setIsCategoryCreated(true));
        dispatch(slice.actions.setaddCategoryShowStatus(message));
        callBack(response);
      } else {
        const ErrorMessage = response?.data?.error[0];
        dispatch(slice.actions.setIsCategoryCreated(false));
        dispatch(slice.actions.setaddCategoryShowStatus(ErrorMessage));
        callBack(response);
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.setIsCategoryCreated(false));
      dispatch(slice.actions.setaddCategoryShowStatus('An error occurred. Please try again.'));
    }
  };
  // return async () => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     const response = await axios.put(`/MasterCategory`, data);
  //     if (response?.data?.status === false) {
  //       dispatch(slice.actions.editCategoryStatus({ status: 'error', error: response?.data }));
  //     } else
  //       dispatch(
  //         slice.actions.editCategoryStatus({ status: 'success', ...response?.data?.result })
  //       );
  //   } catch (error) {
  //     Sentry.captureException(error);
  //     dispatch(slice.actions.hasError(error));
  //     dispatch(slice.actions.editCategoryStatus({ status: 'error', error: error }));
  //   }
  // };
}

export function setEditCategory(data: any) {
  return dispatch(slice.actions.editCategoryStatus(data));
}

export function deleteCategory(categoryId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/MasterCategory/${categoryId}`);
      if (response?.data?.status === false) {
        dispatch(slice.actions.deleteCategoryStatus({ status: 'error', error: response?.data }));
      } else
        dispatch(
          slice.actions.deleteCategoryStatus({ status: 'success', ...response?.data?.result })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.deleteCategoryStatus({ status: 'error', error: error }));
    }
  };
}

export function setDeleteCategory(data: any) {
  return dispatch(slice.actions.deleteCategoryStatus(data));
}

export function ShowIsCategoryCreated(data: any) {
  return dispatch(slice.actions.setIsCategoryCreated(data));
}
export function ShowAddCategoryShowStatus(data: any) {
  return dispatch(slice.actions.setaddCategoryShowStatus(data));
}

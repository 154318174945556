import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormHeader from '../FormHeader';
import { createB2bCompany, editB2b, resetError, setIsCompanyCreatedData } from 'redux/slices/b2b';
import { B2bCompanies } from '../../../@types/b2b';
import useLocales from 'hooks/useLocales';

type B2bCompanyFormProps = {
  isEdit: boolean;
  b2bCompany?: null | B2bCompanies;
  setDisplay: Function;
};

export default function B2bCompanyForm({ isEdit, b2bCompany, setDisplay }: B2bCompanyFormProps) {
  const { translate, currentLang } = useLocales();
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, isCompanyCreated } = useSelector((state: RootState) => state.b2b);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getErrorMessage = (arMessage: string, enMessage: string) =>
    currentLang?.value === 'ar' ? arMessage : enMessage;

  const NewUserSchema = isEdit
    ? Yup.object().shape({
        shortName: Yup.string().required(
          getErrorMessage('الأسم المختصر مطلوب', 'Short name is required')
        ),
        fullName: Yup.string().required(
          getErrorMessage('الأسم الكامل مطلوب', 'Full name is required')
        ),
        contactEmail: Yup.string()
          .required(getErrorMessage('البريد الالكترونى مطلوب', 'Email is required'))
          .email(getErrorMessage('يجب أن يكون البريد الإلكتروني صالحًا', 'Email must be valid')),
        description: Yup.string().required(
          getErrorMessage('الوصف مطلوب', 'Description is required')
        ),
        crNumber: Yup.string()
          .required(getErrorMessage('الرثم الضريبي مطلوب', 'CR number is required'))
          .matches(
            /^\d+$/,
            getErrorMessage('يجب أن يكون الرقم الضريبي رقمًا فقط', 'CR number must be digits only')
          )
          .min(
            9,
            getErrorMessage(
              'يجب أن يكون الرقم الضريبي 9 أرقام على الأقل',
              'CR number must be at least 9 digits'
            )
          )
          .max(
            10,
            getErrorMessage(
              'يجب أن يكون الرقم الضريبي 10 أرقام كحد أقصى',
              'CR number must be at most 10 digits'
            )
          ),
        contactNo: Yup.string()
          .required(getErrorMessage('رقم التواصل مطلوب', 'Contact number is required'))
          .matches(
            /^\d+$/,
            getErrorMessage(
              'يجب أن يكون رقم التواصل رقمًا فقط',
              'Contact number must be digits only'
            )
          )
          .min(
            9,
            getErrorMessage(
              'يجب أن يكون رقم التواصل 9 أرقام على الأقل',
              'Contact number must be at least 9 digits'
            )
          )
          .max(
            10,
            getErrorMessage(
              'يجب أن يكون رقم التواصل 10 أرقام كحد أقصى',
              'Contact number must be at most 10 digits'
            )
          ),
        address: Yup.string().required(getErrorMessage('العنوان مطلوب', 'Address is required')),
        lattitude: Yup.number().required(getErrorMessage('العنوان مطلوب', 'Latitude is required')),
        longitude: Yup.number().required(getErrorMessage('العنوان مطلوب', 'Longitude is required')),
        isActive: Yup.boolean()
      })
    : Yup.object().shape({
        shortName: Yup.string().required(
          getErrorMessage('الأسم المختصر مطلوب', 'Short name is required')
        ),
        fullName: Yup.string().required(
          getErrorMessage('الأسم الكامل مطلوب', 'Full name is required')
        ),
        contactEmail: Yup.string()
          .required(getErrorMessage('البريد الالكترونى مطلوب', 'Email is required'))
          .email(getErrorMessage('يجب أن يكون البريد الإلكتروني صالحًا', 'Email must be valid')),
        description: Yup.string().required(
          getErrorMessage('الوصف مطلوب', 'Description is required')
        ),
        crNumber: Yup.string()
          .required(getErrorMessage('الرثم الضريبي مطلوب', 'CR number is required'))
          .matches(
            /^\d+$/,
            getErrorMessage('يجب أن يكون الرقم الضريبي رقمًا فقط', 'CR number must be digits only')
          )
          .min(
            9,
            getErrorMessage(
              'يجب أن يكون الرقم الضريبي 9 أرقام على الأقل',
              'CR number must be at least 9 digits'
            )
          )
          .max(
            10,
            getErrorMessage(
              'يجب أن يكون الرقم الضريبي 10 أرقام كحد أقصى',
              'CR number must be at most 10 digits'
            )
          ),
        contactNo: Yup.string()
          .required(getErrorMessage('رقم التواصل مطلوب', 'Contact number is required'))
          .matches(
            /^\d+$/,
            getErrorMessage(
              'يجب أن يكون رقم التواصل رقمًا فقط',
              'Contact number must be digits only'
            )
          )
          .min(
            9,
            getErrorMessage(
              'يجب أن يكون رقم التواصل 9 أرقام على الأقل',
              'Contact number must be at least 9 digits'
            )
          )
          .max(
            10,
            getErrorMessage(
              'يجب أن يكون رقم التواصل 10 أرقام كحد أقصى',
              'Contact number must be at most 10 digits'
            )
          ),
        address: Yup.string().required(getErrorMessage('العنوان مطلوب', 'Address is required')),
        lattitude: Yup.number().required(getErrorMessage('العنوان مطلوب', 'Latitude is required')),
        longitude: Yup.number().required(getErrorMessage('العنوان مطلوب', 'Longitude is required')),
        isActive: Yup.boolean()
      });

  const { error, isLoading, isB2bCompanyCreated, updateB2b } = useSelector(
    (state: RootState) => state.b2b
  );

  useEffect(() => {
    // @ts-ignore
    if (error?.errors) {
      // @ts-ignore
      Object?.values(error?.errors)
        ?.flat()
        ?.map((item: any) => enqueueSnackbar(item, { variant: 'error' }));
      dispatch(resetError());
    }
  }, [enqueueSnackbar, error, dispatch]);

  const formik = useFormik({
    initialValues: {
      shortName: isEdit && b2bCompany ? b2bCompany.shortName : '',
      description: isEdit && b2bCompany ? b2bCompany.description : '',
      fullName: isEdit && b2bCompany ? b2bCompany.fullName : '',
      crNumber: isEdit && b2bCompany ? b2bCompany.crNumber : '',
      contactNo: isEdit && b2bCompany ? b2bCompany.contactNo : '',
      contactEmail: isEdit && b2bCompany ? b2bCompany.contactEmail : '',
      address: isEdit && b2bCompany ? b2bCompany.address : '',
      lattitude: isEdit && b2bCompany ? b2bCompany.lattitude : 0,
      longitude: isEdit && b2bCompany ? b2bCompany.longitude : 0,
      isActive: isEdit && b2bCompany ? b2bCompany.isActive : true,
      companyUser: [],
      allowedCoupon: isEdit && b2bCompany ? b2bCompany?.allowedCoupon : '',
      availableCoupon:
        isEdit && b2bCompany
          ? b2bCompany?.availableCoupon === null
            ? b2bCompany?.allowedCoupon
            : b2bCompany?.availableCoupon
          : ''
    },
    validationSchema: NewUserSchema,
    validateOnMount: true,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setLoading(true);
        setSubmitting(true);
        if (isEdit && b2bCompany) {
          dispatch(
            editB2b({
              ...values,
              id: b2bCompany.id,
              modalClose: setDisplay,
              callback: (data: any) => {
                if (data?.status === 200 && data?.data?.status === true) {
                  enqueueSnackbar(data?.data?.result, {
                    variant: 'success'
                  });
                  setDisplay(false);
                  setLoading(false);
                } else if (data?.status === 200 && data?.data?.status === false) {
                  enqueueSnackbar(data?.data?.errors?.[0], {
                    variant: 'error'
                  });
                  setLoading(false);
                }
              }
            })
          );
        } else {
          dispatch(
            createB2bCompany({
              ...values,
              companyUser: [],
              callback: (data: any) => {
                if (data?.status === 200 && data?.data?.status === true) {
                  enqueueSnackbar(data?.data?.result?.Message, {
                    variant: 'success'
                  });
                  setDisplay(false);
                  setLoading(false);
                } else if (data?.status === 200 && data?.data?.status === false) {
                  enqueueSnackbar(data?.data?.errors?.[0], {
                    variant: 'error'
                  });
                  setLoading(false);
                }
              }
            })
          );
          // dispatch(createB2bCompany({ ...values, companyUser: [] }));
        }
        setSubmitting(false);
        resetForm();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } =
    formik;

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background:
          formik.isValid &&
          (theme.palette.mode === 'dark'
            ? `${theme.palette.error.darker} !important`
            : `${theme.palette.error.lighter} !important`)
      }
    }
  });
  const classes = useStyles();

  return (
    <>
      {isEdit && !isSubmitting && isLoading ? (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', pt: '12px' }}>
          <CircularProgress color="secondary" />
        </Stack>
      ) : (
        <FormikProvider value={formik}>
          <FormHeader
            setDisplay={setDisplay}
            Heading={!isEdit ? translate('create a new company') : 'تعديل شركة'}
          />

          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {/* <Card sx={{ p: 3 }}> */}
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('short name')}
                      </Typography>
                      <TextField
                        variant="outlined"
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('shortName')}
                        error={Boolean(touched.shortName && errors.shortName)}
                        helperText={touched.shortName && errors.shortName}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('full name')}
                      </Typography>
                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('fullName')}
                        error={Boolean(touched.fullName && errors.fullName)}
                        helperText={touched.fullName && errors.fullName}
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('description')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('tax number')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('crNumber')}
                        error={Boolean(touched.crNumber && errors.crNumber)}
                        helperText={touched.crNumber && errors.crNumber}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only numeric input and limit to 10 characters
                          if (/^[0-9]*$/.test(value) && value.length <= 10) {
                            formik.setFieldValue('crNumber', value);
                          }
                        }}
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('contact number')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('contactNo')}
                        error={Boolean(touched.contactNo && errors.contactNo)}
                        helperText={touched.contactNo && errors.contactNo}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only numeric input and limit to 10 characters
                          if (/^[0-9]*$/.test(value) && value.length <= 10) {
                            formik.setFieldValue('contactNo', value);
                          }
                        }}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>{translate('email')}</Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('contactEmail')}
                        error={Boolean(touched.contactEmail && errors.contactEmail)}
                        helperText={touched.contactEmail && errors.contactEmail}
                        autoComplete="mail"
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('address')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('latitudes')}
                      </Typography>

                      <TextField
                        type="number"
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('lattitude')}
                        error={Boolean(touched.lattitude && errors.lattitude)}
                        helperText={touched.lattitude && errors.lattitude}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('location')}
                      </Typography>

                      <TextField
                        type="number"
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('longitude')}
                        error={Boolean(touched.longitude && errors.longitude)}
                        helperText={touched.longitude && errors.longitude}
                      />
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('Allowed Coupons')}
                      </Typography>

                      <TextField
                        type="number"
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('allowedCoupon')}
                        error={Boolean(touched.allowedCoupon && errors.allowedCoupon)}
                        helperText={touched.allowedCoupon && errors.allowedCoupon}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('Available Coupons')}
                      </Typography>

                      <TextField
                        type="number"
                        className={classes.root}
                        fullWidth
                        label=""
                        disabled
                        sx={{ cursor: 'not-allowed' }}
                        {...getFieldProps('availableCoupon')}
                        error={Boolean(touched.availableCoupon && errors.availableCoupon)}
                        helperText={touched.availableCoupon && errors.availableCoupon}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Grid>

              {/* //---------------------- */}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        // setDisplay(false)
                        setFieldValue('isActive', event.target.checked);
                      }}
                      checked={formik.values.isActive}
                    />
                  }
                  label={translate('availability')}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  disabled={isEdit ? false : !formik.isValid}
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  {!isEdit ? translate('create') : 'تعديل'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { Bolt, Group, Bookmark, GridViewRounded, Category } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import ArticleIcon from '@mui/icons-material/Article';

// ----------------------------------------------------------------------

const getIcon = (name: string, extentions?: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.${extentions || 'svg'}`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  main: getIcon('pie-chart', 'png'),
  user: getIcon('ic_user'),
  request: getIcon('ic_ticket'),
  appointment: getIcon('ic_calendar'),
  schedule: getIcon('ic_schedule-send'),
  categories: getIcon('ic_category', 'png'),
  packages: getIcon('package', 'png'),
  features: getIcon('features', 'png'),
  promotions: getIcon('promotions', 'png'),
  complaint: getIcon('complaint', 'png'),
  musher: getIcon('musher', 'png'),
  b2b: getIcon('b2b', 'png'),
  ramdan: getIcon('ramdan', 'webp')
};
export const clientSidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // MANAGEMENT : request
      {
        title: 'الطلبات',
        path: PATH_DASHBOARD.request.clientRoot,
        icon: ICONS.request
      }
    ]
  }
];

export const lawyerSidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // MANAGEMENT : request
      {
        title: 'الرئيسية',
        path: PATH_DASHBOARD.main,
        icon: ICONS.main
      },
      {
        title: 'الطلبات',
        path: PATH_DASHBOARD.request.lawyerRoot,
        icon: <Icon height="1.5rem" icon="dashicons:text-page" />
      },
      /*
      {
        title: 'جداول التذاكر المحجوزة',
        path: PATH_DASHBOARD.calendar.root,
        icon: ICONS.request,
      },
      */
      {
        title: 'الجداول ',
        path: PATH_DASHBOARD.availability.root,
        icon: ICONS.schedule
      },
      {
        title: ' المواعيد',
        path: PATH_DASHBOARD.appointments.root,
        icon: <Icon height="1.5rem" icon="bxs:calendar" />
      }
    ]
  }
];

export const adminSidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '', // 'لوحة التحكم'
    items: [
      // MANAGEMENT : request
      {
        title: 'main',
        path: PATH_DASHBOARD.main,
        icon: ICONS.main
      },
      {
        title: 'requests',
        path: PATH_DASHBOARD.request.adminRoot,
        icon: <Icon height="1.5rem" icon="dashicons:text-page" />
      },
      {
        title: 'Master Category', // New Item
        path: PATH_DASHBOARD.Master_Category.list, // Add the path for B2B Customer Name
        icon: <Category /> // Use an appropriate icon
      },
      {
        title: 'appointments',
        path: PATH_DASHBOARD.appointments.admin,
        icon: <Icon height="1.5rem" icon="bxs:calendar" />
      },
      {
        title: 'schedule demo',
        path: PATH_DASHBOARD.scheduleDemo.root,
        icon: <Icon height="1.5rem" icon="ri:calendar-schedule-fill" />
      },
      {
        title: 'lawyers',
        path: PATH_DASHBOARD.lawyer.list,
        icon: ICONS.user
      },
      {
        title: 'News',
        path: PATH_DASHBOARD.News_managment.list,
        icon: <ArticleIcon style={{ fontSize: '1.5rem' }} />
      },
      {
        title: 'adviser',
        path: PATH_DASHBOARD.musher.root,
        icon: ICONS.musher
      },
      {
        title: 'companies',
        path: PATH_DASHBOARD.b2b.root,
        icon: ICONS.b2b
      },
      {
        title: 'shwra Al-Khair',
        path: PATH_DASHBOARD.ramdan.admin,
        icon: ICONS.ramdan
      },
      {
        title: 'B2B Customer', // New Item
        path: PATH_DASHBOARD.Customer_b2b.list, // Add the path for B2B Customer Name
        icon: <Icon height="1.5rem" icon="mdi:account-group" /> // Use an appropriate icon
      },

      {
        title: 'customers',
        path: PATH_DASHBOARD.client.list,
        icon: <Group />
      },
      {
        title: 'categories',
        path: PATH_DASHBOARD.categories.admin,
        icon: <GridViewRounded />
      },
      {
        title: 'package features',
        path: PATH_DASHBOARD.features.root,
        icon: <Bolt />
      },
      {
        title: 'packages',
        path: PATH_DASHBOARD.packages.root,
        icon: <Bookmark />
      },
      {
        title: 'discount codes',
        path: PATH_DASHBOARD.promotions.root,
        icon: <Icon height="1.5rem" icon="ri:coupon-fill" />
      },
      {
        title: 'complaints and suggestions',
        path: PATH_DASHBOARD.complaints.root,
        icon: <Icon height="1.5rem" icon="ion:documents" />
      },
      {
        title: 'employees',
        path: PATH_DASHBOARD.employee.root,
        icon: <Icon height="1.5rem" icon="clarity:employee-group-solid" />
      },
      {
        title: 'monthly report',
        path: PATH_DASHBOARD.report.root,
        icon: <Icon height="1.5rem" icon="material-symbols:chart-data" />
      },
      {
        title: 'permissions',
        path: PATH_DASHBOARD.permission.root,
        icon: <Icon height="1.5rem" icon="fluent-mdl2:permissions-solid" />
      },
      {
        title: 'blogs',
        path: PATH_DASHBOARD.blogs.root,
        icon: <Icon height="1.5rem" icon="mdi:blog" />
      }
    ]
  }
];

import { Chat } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
  IconButton
} from '@mui/material';
import { MIconButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import Scrollbar from 'components/Scrollbar';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getNewChatData } from 'redux/slices/chat';
import moment from 'moment';
import DashboardChatBox from 'components/_dashboard/dashboardChatBox/DashboardChatBox';
import InfiniteScroll from 'react-infinite-scroll-component';

const ChatPopOver = () => {
  const dispatch = useDispatch();
  const { appointmentDetails, isLoading } = useSelector((state: RootState) => state?.appointment);
  const { myProfile } = useSelector((state: RootState) => state.user);
  const { adminAllMessages } = useSelector((state: RootState) => state.chat);

  const [openChatBox, setOpenChatBox] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [referenceId, setReferenceId] = useState('');

  useEffect(() => {
    dispatch(getNewChatData(page, 15));
  }, [dispatch, page]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenChatBox(false);
    setSelectedNotification(null);
  };

  const handleNotificationClick = (notification: any) => {
    setSelectedNotification(notification);
    setOpenChatBox(true);
  };

  const handleBackButtonClick = () => {
    setOpenChatBox(false);
    setSelectedNotification(null);
  };

  const NextPage = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (selectedNotification?.referenceTypeId === 'Appointment') {
      setReferenceId('0');
    } else if (selectedNotification?.referenceTypeId === 'MasterRequestApproved') {
      setReferenceId('2');
    } else if (selectedNotification?.referenceTypeId === 'MasterRequest') {
      setReferenceId('1');
    } else {
      setReferenceId('');
    }
  }, [selectedNotification]);

  function NotificationItem({ notification }: { notification: any }) {
    const formattedDate = moment(notification?.createdDate).format('YYYY-MM-DD');

    return (
      <ListItemButton
        onClick={() => handleNotificationClick(notification)}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(!notification.isRead && {
            bgcolor: 'action.selected'
          })
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              {notification?.fromUserName}
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              {formattedDate}
            </Typography>
          }
        />
      </ListItemButton>
    );
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge
          badgeContent={adminAllMessages?.result?.unReadMsgCount}
          color="primary"
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <Chat />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, height: 560 }}
      >
        {openChatBox ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
              <IconButton onClick={handleBackButtonClick} sx={{ mr: 1 }}>
                <Box component={Icon} icon="eva:arrow-back-fill" />
              </IconButton>
              {/* Show chat information */}
            </Box>
            <DashboardChatBox
              userId={selectedNotification?.toUserId} // ToUserId
              id={selectedNotification?.referenceId} // referenceId
              toUserId={selectedNotification?.fromUserId}
              type={Number(referenceId)} // referenceTypeId
              openChatBox={openChatBox}
              closeChatBox={() => setOpenChatBox(false)}
              createdDate={selectedNotification?.createdDate} // createdDate
            />
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5, overflow: 'auto' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">Conversations</Typography>
              </Box>
            </Box>
            <Divider />
            <Scrollbar sx={{ height: { xs: 340, sm: 500 } }}>
              <Box style={{ height: '500px' }}>
                <List
                  disablePadding
                  subheader={
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      New
                    </ListSubheader>
                  }
                >
                  {adminAllMessages?.result?.recentMessages
                    .filter((n: any) => !n.isRead)
                    .map((data: any) => (
                      <NotificationItem key={data.userId} notification={data} />
                    ))}
                </List>
                <List
                  disablePadding
                  subheader={
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      Old
                    </ListSubheader>
                  }
                >
                  <Box>
                    {adminAllMessages?.result?.oldMessages
                      .filter((n: any) => !n.isRead)
                      .map((data: any) => (
                        <NotificationItem key={data.userId} notification={data} />
                      ))}
                  </Box>
                </List>
              </Box>
            </Scrollbar>
          </>
        )}
      </MenuPopover>
    </>
  );
};

export default ChatPopOver;

import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { AppointmentState } from '../../@types/appointment';

// ----------------------------------------------------------------------

const initialState: AppointmentState = {
  isLoading: false,
  error: false,
  commingAppointmentList: [],
  UpcommingAppointmentList: [],
  lastAppointmentList: [],
  cancelAppointmentStatusRefund: {},
  currentStatus: true,
  adminAppointmentsList: {
    totalCount: 0,
    page: 0,
    size: 0,
    appointment: []
  },
  appointmentDetails: {
    appointmentId: '',
    statusId: 0,
    statusName: '',
    appointmentDate: '',
    createdDate: '',
    categoryId: 0,
    subCategoryId: '',
    customerId: '',
    categoryName: '',
    customerName: '',
    customerNumber: '',
    lawyerId: '',
    lawyerName: null,
    lawyerNumber: '',
    attachedFiles: [],
    details: '',
    customerImage: '',
    lawyerImage: '',
    appointmentTime: '',
    package: {},
    changeLawyerRecordLogs: [],
    referenceNumber: '',
    writtenConsultationPdfUrl: ''
    // appointmentFeeback:[]
  },
  appointmentStatus: [],
  lawyerAppointments: {
    totalCount: 0,
    page: 0,
    size: 0,
    appointments: []
  },
  appointmentCallStatus: {},
  changeAppointmentLawyerStatus: {},
  addAppointmentByAdminStatus: {},
  editAppointmentByAdminStatus: {},
  availableLawyers: [],
  cancelAppointmentStatus: {},
  changeAppointmentStatusResponse: {},
  allSlots: undefined
};

const slice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addAppointmentByAdminSuccess(state, action) {
      state.isLoading = false;
      state.addAppointmentByAdminStatus = action.payload;
    },
    editAppointmentByAdminSuccess(state, action) {
      state.isLoading = false;
      state.editAppointmentByAdminStatus = action.payload;
    },
    getAvailableLawyersSuccess(state, action) {
      state.isLoading = false;
      state.availableLawyers = action.payload;
    },
    getAllSlotstSuccess(state, action) {
      state.isLoading = false;
      state.allSlots = action.payload;
    },
    // GET CLIENT LIST
    getCommingAppointmentListSuccess(state, action) {
      state.isLoading = false;
      state.commingAppointmentList = action.payload;
    },
    // GET Upcomming Appointment LIST
    getUpCommingAppointmentListSuccess(state, action) {
      state.isLoading = false;
      state.UpcommingAppointmentList = action.payload;
    },
    // GET CLIENT LIST
    getLastAppointmentListSuccess(state, action) {
      state.isLoading = false;
      state.lastAppointmentList = action.payload;
    },

    // GET ADMIN LIST
    getAdminAppointmentListSuccess(state, action) {
      state.isLoading = false;
      state.adminAppointmentsList = action.payload;
    },
    cancelAppointmentStatus(state, action) {
      state.isLoading = false;
      state.cancelAppointmentStatus = action.payload;
    },
    // GET APPOINTMENT STATUS
    getAppointmentStatusSuccess(state, action) {
      state.isLoading = false;
      state.appointmentStatus = action.payload;
    },
    // GET APPOINTMENT DETAILS
    getAppointmentDetailsSuccess(state, action) {
      state.isLoading = false;
      state.appointmentDetails = action.payload;
    },
    // GET USER APPOINTMENTS
    getLawyerAppointmensSuccess(state, action) {
      state.isLoading = false;
      state.lawyerAppointments = action.payload;
    },
    getAppointmentCallSuccess(state, action) {
      state.isLoading = false;
      state.appointmentCallStatus = action.payload;
    },
    changeAppointmentLawyerSuccess(state, action) {
      state.isLoading = false;
      state.changeAppointmentLawyerStatus = action.payload;
    },
    changeAppointmentStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeAppointmentStatusResponse = action.payload;
    },
    cancelAppointmentStatusRefunds(state, action) {
      state.isLoading = false;
      state.cancelAppointmentStatusRefund = action.payload;
    },
    currentStatusAppointment(state, action) {
      state.currentStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

//-------------------------------------------------------------------------------------

export function addAppointmentByAdmin(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/CreateAppointment`, data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.addAppointmentByAdminSuccess({ status: 'error', error: response?.data })
        );
      } else
        dispatch(
          slice.actions.addAppointmentByAdminSuccess({
            status: 'success',
            appointmentId: response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.addAppointmentByAdminSuccess({ status: 'error', error: error }));
    }
  };
}

export function setAddAppointmentByAdmin(data: any) {
  return dispatch(slice.actions.addAppointmentByAdminSuccess(data));
}

export function editAppointmentByAdmin(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/Appointments/ChangeAppointmentDateAndTime`, data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.editAppointmentByAdminSuccess({ status: 'error', error: response?.data })
        );
      } else
        dispatch(
          slice.actions.editAppointmentByAdminSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.editAppointmentByAdminSuccess({ status: 'error', error: error }));
    }
  };
}

export function editAppointmentByAdmin2(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/UpdateAppointmentDetails`, data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.editAppointmentByAdminSuccess({ status: 'error', error: response?.data })
        );
      } else
        dispatch(
          slice.actions.editAppointmentByAdminSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.editAppointmentByAdminSuccess({ status: 'error', error: error }));
    }
  };
}

export function setEditAppointmentByAdmin(data: any) {
  return dispatch(slice.actions.editAppointmentByAdminSuccess(data));
}

//Newly Added Api for Available Lawyers

export function getAvailableLawyers(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/AvailableLawyers`, data);
      dispatch(slice.actions.getAvailableLawyersSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getAvailableLawyersSuccess({ status: 'error', error: error }));
    }
  };
}

export function getAllSlots(date: string, lawyerId: any = '') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Slot/GetAllSlots?date=${date}&lawyerId=${lawyerId}`, {});
      dispatch(slice.actions.getAllSlotstSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCommingAppointmentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('/apis/comming-appointment.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const data = await response.json();

      dispatch(slice.actions.getCommingAppointmentListSuccess(data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getLastAppointmentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('/apis/last-appointment.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const data = await response.json();

      dispatch(slice.actions.getLastAppointmentListSuccess(data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------------

export function getAdminAppointmentList(data: any) {
  return async (dispatch: any) => {
    // Don't forget to pass dispatch as a parameter here
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Appointments/GetAll', data);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));
      } else {
        dispatch(slice.actions.getAdminAppointmentListSuccess(response?.data?.result));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// UpdateAdminAppointment should return the promise
// export function UpdateAdminAppointment(appointmentId: string, isRefund: boolean) {
//   return async (dispatch: any) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       dispatch(slice.actions.currentStatusAppointment(true))
//       const response = await axios.put(`/AppAdmin/CancelAppointment?appointmentId=${appointmentId}&IsRefund=${isRefund}`);
//       if (response?.data?.status === true) {
//         dispatch(slice.actions.cancelAppointmentStatusRefunds({ status: 'error', error: response?.data }));
//         throw new Error(response?.data?.error || 'Error processing request'); // Throw error to handle rejection
//       } else {
//         dispatch(slice.actions.cancelAppointmentStatusRefunds({ status: 'success', ...response?.data?.result }));
//         return (response.data); // Return response for success handling
//         dispatch(slice.actions.currentStatusAppointment(false))

//       }
//     } catch (error: any) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.currentStatusAppointment(false))
//       dispatch(slice.actions.cancelAppointmentStatusRefunds({ status: 'error', error: error?.message }));
//       throw error; // Throw error to handle rejection
//     }
//   };
// }

export function UpdateAdminAppointment(appointmentId: string, isRefund: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.currentStatusAppointment(true));

      const response = await axios.put(
        `/AppAdmin/CancelAppointment?appointmentId=${appointmentId}&IsRefund=${isRefund}`
      );

      if (response?.status === 200 && response?.data?.status === false) {
        // If the response status is 200 but data indicates an error
        dispatch(
          slice.actions.cancelAppointmentStatusRefunds({
            status: 'error',
            error: response?.data?.error
          })
        );
        throw new Error(response?.data?.error || 'Error processing request');
      } else if (response?.status === 200 && response?.data?.status === true) {
        dispatch(
          slice.actions.cancelAppointmentStatusRefunds({
            status: 'success',
            ...response?.data?.result
          })
        );
        dispatch(slice.actions.currentStatusAppointment(false));
        return response.data;
      }
    } catch (error: any) {
      Sentry.captureException(error);
      dispatch(slice.actions.currentStatusAppointment(false));
      dispatch(
        slice.actions.cancelAppointmentStatusRefunds({ status: 'error', error: error?.message })
      );
      throw error;
    }
  };
}

export function cancelAdminAppointment(appointmentId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/Appointments/CancelAppointment?appointmentId=${appointmentId}`
      );
      if (response?.data?.status === false) {
        dispatch(slice.actions.cancelAppointmentStatus({ status: 'error', error: response?.data }));
      } else
        dispatch(
          slice.actions.cancelAppointmentStatus({ status: 'success', ...response?.data?.result })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.cancelAppointmentStatus({ status: 'error', error: error }));
    }
  };
}

export function changeAppointmentStatus(appointmentId: string, appointmentStatus: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/Appointments/ChangeAppointmentStatus?AppointmentId=${appointmentId}&AppointmentStatus=${appointmentStatus}`
      );
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.changeAppointmentStatusSuccess({
            status: 'error',
            error: response?.data?.errors?.[0] || 'Failed to change appointment status'
          })
        );
        throw new Error(response?.data?.error || 'Failed to change appointment status');
      } else if (response?.data?.status === true) {
        dispatch(
          slice.actions.changeAppointmentStatusSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };
}

export function getAppointmentStatus() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Lookups/AppointmentStatus');
      dispatch(slice.actions.getAppointmentStatusSuccess(response?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//---- Get Upcomming Appointments List------------------------------------------------------------------------

export function getUpCommingAppointmentList() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/UpcomingAppointments');
      dispatch(slice.actions.getUpCommingAppointmentListSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppointmentDetails(appointmentId: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Appointments/${appointmentId}`);
      dispatch(slice.actions.getAppointmentDetailsSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLawyerAppointments(lawyerId: string | number, params?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Appointments/lawyer/${lawyerId}`, { params });
      dispatch(slice.actions.getLawyerAppointmensSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setLawyerAppointments(data?: any) {
  return dispatch(slice.actions.getLawyerAppointmensSuccess(data));
}

export function getAppointmentCall(appointmentId: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/CallCenter/SubmitInfoForCall/${0}/${appointmentId}`);
      dispatch(
        slice.actions.getAppointmentCallSuccess({ ...response?.data?.result, status: 'success' })
      );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getAppointmentCallSuccess({ error, status: 'error' }));
    }
  };
}

export function setAppointmentCall(data?: any) {
  return dispatch(slice.actions.getAppointmentCallSuccess(data));
}

export function changeAppointmentLawyer(
  AppointmentId: string,
  LawyerId: string,
  reason?: string | null
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`Appointments/ChangeAppointmentLawyer`, null, {
        params: {
          AppointmentId: AppointmentId,
          LawyerId: LawyerId,
          Comments: reason
        }
      });
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));
        dispatch(
          slice.actions.changeAppointmentLawyerSuccess({ error: response?.data?.errors?.[0], status: 'error' })
        );
      } else {
        dispatch(
          slice.actions.changeAppointmentLawyerSuccess({
            ...response?.data?.result,
            status: 'success'
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.changeAppointmentLawyerSuccess({ error, status: 'error' }));
    }
  };
}

export function submitTransactionInvoice(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppCustomer/SubmitTransactionInvoice`, data);
      return { STATUS: true, ...response.data };
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      return { STATUS: false, error };
    }
  };
}

export function getCallRecordHistory(id: string) {
  return async () => {
    try {
      const response = await axios.get(
        `/CallCenter/CallRecordHistory?ReferenceId=${id}&ReferenceType=0`,
        {}
      );
      return response;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function setChangeAppointmentLawyer(data?: any) {
  return dispatch(slice.actions.changeAppointmentLawyerSuccess(data));
}

export function setCancelAppointmentStatus(data: any) {
  return dispatch(slice.actions.cancelAppointmentStatus(data));
}

export function setAppointmentDetails(data: any) {
  return dispatch(slice.actions.getAppointmentDetailsSuccess(data));
}

export function setChangeAppointmentStatus(data: any) {
  return dispatch(slice.actions.changeAppointmentStatusSuccess(data));
}

export function setcurrentStatusAppointment(data: any) {
  return dispatch(slice.actions.currentStatusAppointment(data));
}
